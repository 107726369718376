import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const items = [
  {
    id: 1,
    content: "ආයුබෝවන්",
  },
  {
    id: 2,
    content: "Terve",
  },
  {
    id: 3,
    content: "Hello",
  },
  {
    id: 4,
    content: "Bonjour",
  },
  {
    id: 5,
    content: "Hola",
  },
  {
    id: 6,
    content: "Привет",
  },
  {
    id: 7,
    content: "你好",
  },
  {
    id: 8,
    content: "Ciao",
  },
  {
    id: 9,
    content: "こんにちは",
  },
  {
    id: 10,
    content: "안녕하세요",
  },
  {
    id: 11,
    content: "Cześć",
  },
  {
    id: 12,
    content: "Ahoj",
  },
];

export default function Home() {
  const [message, setMessage] = useState("ආයුබෝවන්");
  const [counter, setCounter] = useState(0);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= items.length - 1) return 0;
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);
  
  return (
    <main className="flex flex-col lg:flex-row gap-6 min-h-screen w-full relative">
      <div className="flex flex-col gap-6 justify-center items-center min-h-screen md:w-full">
        <div className="w-full">
          <p className="uppercase text-purple-100 text-2xl mb-10 flex items-center justify-center">
            <span className="greeting text-teal-200 flex items-center justify-end mr-2 text-4xl">
              <AnimatePresence>
                <motion.div
                  key={items[index].id}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ ease: "easeInOut" }}
                  style={{ position: "absolute" }}
                >
                  {items[index].content}
                </motion.div>
              </AnimatePresence>
            </span>
            <span className="flex items-start">, My name is</span>
          </p>
        </div>
        <div>
          <h1 className="text-white font-bold text-6xl mb-10 w-full">
            Shalinga Manasinghe
          </h1>
          <p className="text-2xl text-teal-300">Web Developer</p>
        </div>
      </div>
      <div className="lg:flex justify-center lg:items-center items-left min-h-screen w-full relative">
        <div className="flex justify-center items-center lg:justify-start">
          <img
            src="/1715110978434.jpg"
            alt="Shalinga Manasinghe"
            className="flex rounded-full w-3/4 items-center"
          />
        </div>
        <div className="flex">
          <img
            src="/samurai1_transparent.png"
            alt="samurai"
            className="h-screen w-auto opacity-30 fixed right-0 top-0 -z-10"
          />
        </div>
      </div>
    </main>
  );
}

import { motion, useScroll } from "framer-motion";

export default function Scroll() { 
    const { scrollYProgress } = useScroll();

    return (
      <motion.div
        className="top-0 left-0 animated-background right-0 h-2 bg-gradient-to-r from-emerald-100 via-blue-500 to-cyan-500 z-10 fixed w-screen"
        style={{ scaleX: scrollYProgress }}
      />
    );
}

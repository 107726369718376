import {
  FaServer,
  FaIdBadge,
  FaDatabase,
  FaCloudMeatball,
  FaComputer,
} from "react-icons/fa6";

export default function Services() {
  return (
    <section
      id="services"
      className="flex min-h-screen items-center justify-center relative mt-10"
    >
      <div className="flex flex-col justify-start w-2/3">
        <div className="text-left">
          <p className="text-teal-300 text-4xl uppercase m-6 font-overlock flex">
            <FaComputer className="mr-7" />
            What I do
          </p>
          <p className="text-white text-2xl font-overlock">
            I Focused on Crafting Seamless Digital Experiences Through Versatile
            Expertise.
          </p>
        </div>
        <div className="mt-10 flex lg:flex-row gap-6 flex-col text-left w-full text-teal-100 justify-between items-center">
          <div className="flex items-center flex-col mt-10">
            <p className="flex text-2xl mb-5">
              <FaServer className="mr-5" />
              BackEnd
            </p>
            <span className="flex text-justify text-xl">
              With extensive experience in Ruby, Laravel, Node.js, and Python, I
              offer a versatile skill set in backend development. My deep
              expertise allows me to design and build scalable, efficient
              backend systems while effectively addressing a broad spectrum of
              backend challenges with confidence and precision.
            </span>
          </div>
          <div className="flex items-center flex-col mt-10">
            <p className="flex text-2xl mb-5">
              <FaIdBadge className="mr-5" />
              FrontEnd
            </p>
            <span className="flex text-justify text-xl">
              With 2 years of hands-on experience in React.js, I bring a solid
              foundation in front-end development to the table. My proficiency
              in React allows me to create interactive and dynamic user
              interfaces that enhance the overall user experience. Additionally,
              I stay updated with the latest developments in front-end
              technologies, enabling me to incorporate best practices and
              innovative solutions into my work.
            </span>
          </div>
          <div className="flex items-center flex-col mt-10">
            <p className="flex text-2xl mb-5">
              <FaDatabase className="mr-5" />
              Database
            </p>
            <span className="flex text-justify text-xl">
              With experience in MySQL, PostgreSQL, and setting up databases in
              AWS Aurora, I possess a comprehensive understanding of database
              management. My proficiency in these technologies enables me to
              design, optimize, and maintain robust database solutions tailored
              to specific project requirements.
            </span>
          </div>
          <div className="flex items-center flex-col mt-10">
            <p className="flex text-2xl mb-5">
              <FaCloudMeatball className="mr-5" />
              Cloud
            </p>
            <span className="flex text-justify text-xl">
              With hands-on experience in building AWS infrastructure and
              deploying applications to platforms like Cloudflare, Heroku and
              DigitalOcean, I bring a wealth of expertise in cloud computing. My
              proficiency in AWS allows me to design scalable and resilient
              architectures, leveraging services such as EC2, S3, and RDS to
              meet project requirements efficiently.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

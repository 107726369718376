export default function Footer() {
  function getYear() {
    return new Date().getFullYear();
}
  return (
    <section id="footer" className="flex justify-center relative m-10">
      <div className="flex w-2/3">
        <div className="text-left">
          <p className="flex text-teal-300 uppercase font-overlock">
            Copyright © {getYear()} Shalinga Manasinghe.
          </p>
        </div>
      </div>
    </section>
  );
}

import { FaFaceGrinHearts } from "react-icons/fa6";
export default function About() {
  return (
    <section
      id="about"
      className="flex min-h-screen items-center justify-center relative"
    >
      <div className="flex flex-col justify-start w-2/3">
        <div className="text-left">
          <p className="text-teal-300 text-4xl uppercase m-6 font-overlock flex">
            <FaFaceGrinHearts className="mr-7" />
            About me
          </p>
          <p className="text-white text-2xl lg:text-5xl font-overlock">
            I am an enthusiastic web developer based in Finland, with a rich
            experience of over 9 years in web applications development.
          </p>
        </div>
        <div className="mt-10 flex lg:flex-row flex-col text-left">
          <div className="flex lg:w-1/4 justify-start">
            <p className="text-teal-300 text-3xl m-6">
              I craft professional & beautiful web applications
            </p>
          </div>
          <div className="lg:w-3/4">
            <p className="text-white text-xl font-overlock text-justify">
              With years of experience in web development I have mastered the
              skills of understanding client requirements according to the
              latest trends. I have worked with businesses from different niches
              so you can rely on me for yours. I’ve spent most of these years
              working across different areas of design like front-end
              development, landing pages, email design, app UI/UX, to my current
              role designing products for mobile platforms. Having worked on
              various projects that are already live, I can help you with the
              best possible suggestions and ideas that we can proceed with. With
              me, you aren’t forced to accept anything. I give you a variety of
              options we can work on together. Read More
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import { FaTimeline, FaAnglesRight } from "react-icons/fa6";
export default function Experience() {
  return (
    <section
      id="experience"
      className="flex min-h-screen items-center justify-center relative"
    >
      <div className="flex flex-col justify-start w-2/3">
        <div className="text-left">
          <p className="flex text-teal-300 text-4xl uppercase m-6 font-overlock">
            <FaTimeline className="mr-7" />
            My Journey
          </p>
        </div>
        <div className="mt-10 flex flex-col gap-10 text-left w-full items-center justify-center text-teal-100">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-1/4">
              <p className="flex text-2xl underline">
                <FaAnglesRight className="mr-6" />
                Digivizer
              </p>
              <p className="flex">Dec.2021 - March.2024</p>
            </div>
            <div className="flex flex-col basis-3/4">
              <p className="text-xl m-5 mt-0">Senior Full Stack Engineer</p>
              <p>
                Developed a custom reporting service to improve flexibility in
                data reporting, tailored to organizational needs for a more
                efficient framework. Integrated social media APIs from platforms
                like Twitter, Microsoft, Facebook, Instagram, LinkedIn, and
                TikTok into the Digivizer platform for seamless data aggregation
                and analysis. Worked closely with the engineering team through
                knowledge sharing, pair programming, and code reviews to enhance
                expertise and code quality. Took full ownership of features from
                design to deployment, ensuring continuous improvement and
                operational excellence.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-1/4">
              <p className="flex text-2xl underline">
                <FaAnglesRight className="mr-6" />
                Pearson
              </p>
              <p className="flex">Dec.2019 - Dec.2021</p>
            </div>
            <div className="flex flex-col basis-3/4">
              <p className="text-xl m-5 mt-0">Associate Technical Specialist</p>
              <p>
                Upgraded a Ruby on Rails application from version 4.2 to 6.1,
                enhancing capabilities and aligning with the latest security
                standards. Developed secure, scalable AWS environments to
                improve deployment processes. Managed customer incidents to
                ensure timely resolutions and high service reliability. Created
                applications with thorough test coverage, debugging, and
                contributed to team success by ensuring high-quality software.
                Collaborated with the Product Owner and Project Manager to align
                key features with the product roadmap. Led the engineering team,
                driving technical strategy and fostering innovation and
                excellence in project execution.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-1/4">
              <p className="flex text-2xl underline">
                <FaAnglesRight className="mr-6" />
                JKCS Pvt Ltd
              </p>
              <p className="flex">Jun.2017 - Dec.2019</p>
            </div>
            <div className="flex flex-col basis-3/4">
              <p className="text-xl m-5 mt-0">Solution Designer</p>
              <p>
                Led a diverse engineering team of developers, QA specialists,
                and an intern, focusing on knowledge sharing, pair programming,
                and code reviews to enhance skills and ensure high-quality
                results. Developed new features, fixed bugs, and improved
                software functionality and user experience. Maintained
                application stability through proactive maintenance and
                continuous improvement. Deployed applications on AWS, leveraging
                its scalable infrastructure for better performance and
                reliability. Mentored team members on the tech stack, promoting
                best practices and enhancing technical skills. Ensured robust
                software performance through comprehensive test coverage and
                active debugging.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-1/4">
              <p className="flex text-2xl underline">
                <FaAnglesRight className="mr-6" />
                MI Solutions Lanka
              </p>
              <p className="flex">Feb.2016 - Jun.2017</p>
            </div>
            <div className="flex flex-col basis-3/4">
              <p className="text-xl m-5 mt-0">Senior Developer</p>
              <p>
                Led an engineering team of four developers and one quality
                engineer, fostering a collaborative environment through
                knowledge sharing, pair programming, and code reviews. Authored
                functional and technical specifications to ensure clear project
                guidelines and alignment with development objectives. Developed
                applications with comprehensive test coverage, resolving issues
                and contributing to high-quality software delivery. Optimized
                performance and scalability by hosting applications on
                DigitalOcean servers. Collaborated closely with the CTO to
                design and deliver projects that align with strategic business
                goals and technological standards.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-1/4">
              <p className="flex text-2xl underline">
                <FaAnglesRight className="mr-6" />
                Venturit
              </p>
              <p className="flex">Sep.2011 - Jan.2016</p>
            </div>
            <div className="flex flex-col basis-3/4">
              <p className="text-xl m-5 mt-0">Web Developer</p>
              <p>
                Developed applications with robust test coverage and resolved
                issues to ensure high reliability and performance. Authored
                functional and technical specifications, along with necessary
                documentation, to clearly outline project requirements and
                effectively guide the development process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import { FaIdCardClip } from "react-icons/fa6";
export default function Portfolio() {
  return (
    <section
      id="portfolio"
      className="flex min-h-screen items-center justify-center relative"
    >
      <div className="flex flex-col justify-start w-2/3">
        <div className="text-left">
          <p className="text-teal-300 text-4xl uppercase m-6 font-overlock flex">
            <FaIdCardClip className="mr-7" />
            Portfolio
          </p>
        </div>
        <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
          <div class="-m-1 flex flex-wrap md:-m-2">
            {/* <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                />
              </div>
            </div> */}
            <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="https://sudoku-master.com/">
                  <img
                    alt="sudoku puzzle game"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src="/solving-sudoku.png"
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="https://github.com/shalingams/twitter-nodejs">
                  <img
                    alt="twitter clone nodejs and typescript"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src="/twitter.png"
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="https://github.com/shalingams/weather-app-nextjs">
                  <img
                    alt="weather app with nextjs"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src="/weather.jpg"
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="https://ravens.shalinga.xyz/">
                  <img
                    alt="simple html game"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src="/ScreenShot.png"
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/3 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="https://photography.shalinga.xyz/">
                  <img
                    alt="photography site"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src="/A291D4DE-EAB1-4DED-8565-0B11A65F61D9-scaled.jpg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import {
  FaPhone,
  FaInstagram,
  FaLinkedin,
  FaEnvelopeOpenText,
} from "react-icons/fa6";
export default function Contact() {
  return (
    <section id="contact" className="flex items-center justify-center relative">
      <div className="flex flex-col justify-start w-2/3">
        <div className="text-left">
          <p className="flex text-teal-300 text-4xl uppercase m-6 font-overlock">
            <FaPhone className="mr-7" />
            Connect
          </p>
        </div>
        <div className="mt-10 flex flex-col gap-10 text-left w-full items-center justify-center text-teal-100">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-4 basis-2/4">
              <div className="flex basis-2/4">
                <img src="/flags1.webp" alt="Flags" />
              </div>
            </div>
            <div className="flex flex-col gap-4 basis-2/4">
              <div className="flex flex-col gap-6 basis-2/4 justify-center">
                <div className="flex">
                  <p className="text-4xl">
                    Based in Finland, working worldwide.
                  </p>
                </div>
                <div className="flex gap-6">
                  <span className="text-2xl">Follow me on</span>
                  <a
                    href="https://www.instagram.com/shalingams"
                    target="_blank"
                    rel="noreferrer"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <FaInstagram
                      aria-hidden="true"
                      className="h-6 w-6 text-pink-500"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/shalingams/"
                    target="_blank"
                    rel="noreferrer"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <FaLinkedin
                      aria-hidden="true"
                      className="h-6 w-6 text-blue-500"
                    />
                  </a>
                </div>
                <div className="flex gap-6">
                  <span className="text-2xl">Email me on</span>
                  <a
                    href="mailto:shalingams@gmail.com"
                    target="_blank"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    rel="noreferrer"
                  >
                    <FaEnvelopeOpenText
                      aria-hidden="true"
                      className="h-6 w-6 text-red-500"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import "./App.css";
import { motion, useScroll } from "framer-motion";
import Navigation from "./components/Navigration";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {  
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  };


  return (
    <motion.div
      className="App font-overlock"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <Navigation />
      <Home />
      <About />
      <Services />
      <Experience />
      <Portfolio />
      <Contact />
      <Footer />
    </motion.div>
  );
}

export default App;
